import { FormElement } from "app/containers/types";

export type CreateImportResponse = {
  userId: string;
  doc: Document;
  currentProgress: number;
  createdAt: Date;
  id: string;
  totalRows: number;
  type: string;
  updatedAt: null | Date;
};

export interface Log {
  message: string;
}

export type ImportLog = {
  id: string;
  importId: string;
  data: Array<Log>;
};

export type LogList = {
  items: Array<ImportLog>;
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    previous: string;
    next: string;
    last: string;
  };
  query: {
    limit: number;
    q: string;
    orderField: string;
    orderDirection: string;
  };
};

export interface ImportsContainerState {
  loading: boolean;
  autoRefresh: boolean;
  logsLoading: boolean;
  list: ImportsList;
  isFormOpen: boolean;
  forUpload: null | File;
  form: ImportForm;
  logs: LogList;
  isLogsOpened: boolean;
}

export type Document = {
  file: string;
  fileName: string;
  fileProcessed: null | boolean;
  fileSize: null | string;
  fileType: string;
  id: string;
  originalName: string;
  thumb: string;
  type: string;
  url: string;
};

export type ImportForm = {
  id: FormElement;
  type: FormElement;
};
export const defaultImportForm: ImportForm = {
  id: { value: "" },
  type: { value: "" },
};

export const defaultImportsContainerState: ImportsContainerState = {
  isFormOpen: false,
  loading: false,
  autoRefresh: true,
  logsLoading: false,
  forUpload: null,
  form: defaultImportForm,
  list: {
    items: [],
    meta: {
      currentPage: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    links: { first: "", last: "", next: "", previous: "" },
    query: {
      page: 1,
      limit: 20,
      orderDirection: "",
      orderField: "",
      q: "",
      type: "",
    },
  },
  logs: {
    items: [],
    links: { first: "", last: "", next: "", previous: "" },
    meta: {
      currentPage: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
    query: { limit: 10, orderDirection: "", orderField: "", q: "" },
  },
  isLogsOpened: false,
};

export type ImportsList = {
  items: Array<any>;
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
  links: {
    first: string;
    last: string;
    next: string;
    previous: string;
  };
  query: {
    page: number;
    limit: number;
    q: string;
    orderField: string;
    orderDirection: string;
    type: string;
  };
};
