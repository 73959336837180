import { FormError } from "app/containers/types";
import set from "lodash/set";
import { scrollBottom } from "styles/theme/utils";
import { createSlice } from "utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";

import { CreateImportResponse, defaultImportsContainerState } from "./types";

const scrollLogsToEnd = () => {
  const scrollDiv: any = document.getElementById("default-scroll");
  scrollBottom(scrollDiv);
};

export const importsSlice = createSlice({
  name: "importsState",
  initialState: defaultImportsContainerState,
  reducers: {
    getList: (state, action) => {
      state.loading = true;
      state.list.query.page = 1;
    },
    getListSuccess: (state, action) => {
      state.loading = false;
      state.list.meta = action.payload.meta;
      if (state.list.meta.currentPage == 1) {
        state.list.items = action.payload.items;
      } else {
        state.list.items = [...state.list.items, ...action.payload.items];
      }
      state.list.links = action.payload?.links;
    },
    getListFailed: (state, action) => {
      state.loading = false;
    },
    getNextPageItems: (state, action) => {
      state.loading = true;
      state.list.query.page = state.list.meta.currentPage + 1;
    },
    getNextPageItemsSuccess: (state, action) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
    },
    getNextPageItemsFailed: (state, action) => {
      state.loading = false;
    },
    toggleFormOpen: (state) => {
      state.isFormOpen = !state.isFormOpen;
    },
    selectFileToUpload: (state, action: PayloadAction<File>) => {
      state.forUpload = action.payload;
    },
    setFormField: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      const { name, value } = action.payload;
      set(state.form, `${name}.value`, value);
      set(state.form, `${name}.error`, "");
    },
    createImport: (state, action) => {
      state.loading = true;
    },
    createImportSuccess: (
      state,
      action: PayloadAction<CreateImportResponse>
    ) => {
      state.loading = false;
      state.list.items = [action.payload, ...state.list.items];
      state.form = defaultImportsContainerState.form;
      state.forUpload = defaultImportsContainerState.forUpload;
      state.isFormOpen = false;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.list.items = [];
      state.list.query.page = 1;
      state.list.links = defaultImportsContainerState.list.links;
      state.list.meta = defaultImportsContainerState.list.meta;
      set(state.list.query, name, value);
    },
    setType: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.list.query.type = value;
    },
    createImportFailed: (state, action) => {
      state.loading = false;
    },
    resetForm: (state) => {
      state.forUpload = defaultImportsContainerState.forUpload;
      state.form = defaultImportsContainerState.form;
    },
    setFormErrors: (state, action: PayloadAction<Array<FormError>>) => {
      action.payload.forEach((item) => {
        set(state.form, `${item.name}.error`, item.error);
      });
    },
    setAutoRefresh: (state) => {
      state.autoRefresh = !state.autoRefresh;
    },
    getImportLogById: (state, action) => {
      state.logsLoading = true;
    },
    getImportLogByIdSuccess: (state, action) => {
      state.logsLoading = false;
      state.logs.items = action.payload.items;
      state.logs.links = action.payload.links;
      state.logs.meta = action.payload.meta;
      const productImport = action.payload.import;
      const item = state.list.items.find((f) => f.id == productImport.id);
      if (item && productImport) {
        item.id = productImport.id;
        item.processStatus = productImport.processStatus;
        item.updatedAt = productImport.updatedAt;
        item.currentProgress = productImport.currentProgress;
        item.totalRows = productImport.totalRows;
      }
      if (state.autoRefresh) {
        setImmediate(() => {
          scrollLogsToEnd();
        });
      }
      //Enable Process import
      const index = state.list.items.findIndex(
        (x) => x.id === action.payload.import.id
      );
      state.list.items[index].processImportInProgress = false;
    },
    getImportLogByIdFailed: (state, action) => {
      state.loading = false;
    },
    toggleLogsDialog: (state) => {
      state.isLogsOpened = !state.isLogsOpened;
      if (state.isLogsOpened == false) {
        state.logs.items = [];
      }
    },
    resetLogsDialog: (state) => {
      state.isLogsOpened = false;
      state.logs = defaultImportsContainerState.logs;
    },
    processImport: (state, action) => {
      state.loading = true;

      //Disable Process import
      const index = state.list.items.findIndex((x) => x.id === action.payload);
      state.list.items[index].processImportInProgress = true;
    },
    processImportSuccess: (state, action) => {
      state.loading = false;
    },
    processImportFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export const { actions, reducer, name: sliceKey } = importsSlice;
